// extracted by mini-css-extract-plugin
export var cardItem = "styles-module--cardItem--3QhWA";
export var circle = "styles-module--circle--2zn6Q";
export var connector = "styles-module--connector--3Opje";
export var content = "styles-module--content--1aZKo";
export var cta = "styles-module--cta--kr0cB";
export var fgChapter = "styles-module--fgChapter--fX93l";
export var fgModule = "styles-module--fgModule--3GLF0";
export var leftBottom = "styles-module--leftBottom--1wyM5";
export var leftTop = "styles-module--leftTop--2gK59";
export var line = "styles-module--line--3jm3E";
export var readNextCard = "styles-module--readNextCard--2ScwF";
export var rightContentWrap = "styles-module--rightContentWrap--3Yn8X";
export var table = "styles-module--table--2uqZf";
export var tableCell = "styles-module--tableCell--3Aw7a";
export var textAboveContent = "styles-module--textAboveContent--2WEkK";
export var title = "styles-module--title--3XJcr";
export var tools = "styles-module--tools--iPYLP";